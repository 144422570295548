import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import './assets/js/iconfont.js'
import modal from './modal'
Vue.directive('watermark', /* 导入的自定义指令 */);

import {convertPdfPageToImage} from './pdf.js'
// console.log(convertPdfPageToImage,"+++++")
Vue.prototype.convertPdfPageToImage = convertPdfPageToImage
Vue.config.productionTip = false
Vue.use(ElementUI);     //全局注入element
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'huLeTyIhXVbaN1R6AVM1yLAcDROpNR59'
})
import VueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(VueSeamlessScroll)
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBNf_BlkjgyzUqSnfQIwyqn5_qg0N00of8', // 替换为你的 Google Maps API 密钥
    libraries: 'places', // 如果你需要使用 Places API
  },
});
Vue.prototype.$modal = modal
import echarts from 'echarts'
import animated from "animate.css";
Vue.prototype.$echarts = echarts
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
