<template>
	<div class="container">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data(){
			return {}
		}
	}
</script>

<style lang="scss">
	body {
		margin: 0px!important;
	}

</style>
