import * as pdfjsLib from 'pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.mjs")
export async function convertPdfPageToImage(pdfUrl, pageIndex = 1) {
	console.log(pdfUrl, "0000")
	return new Promise((resolve, reject) => {
		// 初始化PDF阅读器
		const loadingTask = pdfjsLib.getDocument(pdfUrl);

		loadingTask.promise.then(function(pdf) {
			// 获取指定页码的PDF页面
			pdf.getPage(pageIndex).then(function(page) {
				const viewport = page.getViewport({
					scale: 1
				});
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');

				canvas.height = viewport.height;
				canvas.width = viewport.width;

				const renderContext = {
					canvasContext: context,
					viewport,
				};

				// 渲染PDF页面到canvas
				page.render(renderContext).promise.then(() => {
					// 将canvas内容转换为Data URL形式的图片
					resolve(canvas.toDataURL('image/png'));
				}, reject);
			});
		}, reject);
	});
}